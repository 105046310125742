import React from 'react';
import websiteImage from '../assets/website_image.png';
import './CoverComponent.scss';

const CoverComponent = ({ toggleContact }) => {
	return (
		<div className="cover-component">
			<div className="left-content">
				<h2>WEBSITE DEVELOPMENT</h2>
				<h1>Better Manage Your <span className="highlight">Technology</span> Infrastructure</h1>
				<p>The right managed IT support can solve your business problems and move your company forward</p>
				<button className="consultation-button" onClick={toggleContact}>Free Consultation</button>
			</div>
			<div className="right-content">
				<img src={websiteImage} alt="Website" />
			</div>
		</div>
	);
};

export default CoverComponent;
