import React from 'react';
import './Footer.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCode, faLaptopCode, faHeadset, faBullhorn } from '@fortawesome/free-solid-svg-icons';

function Footer() {
	return (
		<div className="footer">
			<div className="section">
				<FontAwesomeIcon icon={faLaptopCode} size="2x" />
				<h3>WEBSITES</h3>
				<p>Customized web solutions to establish and grow your online presence.</p>
			</div>
			<div className="section">
				<FontAwesomeIcon icon={faCode} size="2x" />
				<h3>SOFTWARE</h3>
				<p>Building innovative software applications to drive your business forward.</p>
			</div>
			<div className="section">
				<FontAwesomeIcon icon={faHeadset} size="2x" />
				<h3>TECH SUPPORT</h3>
				<p>Providing 24/7 tech support to keep your operations running smoothly.</p>
			</div>
			<div className="section">
				<FontAwesomeIcon icon={faBullhorn} size="2x" />
				<h3>MARKETING</h3>
				<p>Strategic marketing services for increased visibility and engagement.</p>
			</div>
		</div>
	);
}

export default Footer;
