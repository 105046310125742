import React, { useState, useRef, useEffect } from 'react';
import './IndustriesComponent.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';

// Importing new industry images
import ecommerceImg from '../assets/ecommerce.webp';
import ecommerceTismImg from '../assets/ecommerce_tism.webp';
import healthcareImg from '../assets/healthcare.webp';
import healthcareTismImg from '../assets/healthcare_tism.webp';
import realEstateImg from '../assets/real_estate.webp';
import realEstateTismImg from '../assets/real_estate_tism.webp';
import educationImg from '../assets/education.webp';
import educationTismImg from '../assets/education_tism.webp';
import financeImg from '../assets/finance.webp';
import financeTismImg from '../assets/finance_tism.webp';
import hospitalityImg from '../assets/hospitality.webp';
import hospitalityTismImg from '../assets/hospitality_tism.webp';
import restaurantsImg from '../assets/restaurants.webp';
import restaurantsTismImg from '../assets/restaurants_tism.webp';
import legalImg from '../assets/legal.webp';
import legalTismImg from '../assets/legal_tism.webp';
import entertainmentImg from '../assets/entertainment.webp';
import entertainmentTismImg from '../assets/entertainment_tism.webp';
import technologyImg from '../assets/technology.webp';
import technologyTismImg from '../assets/technology_tism.webp';
import professionalImg from '../assets/professional.webp';
import professionalTismImg from '../assets/professional_tism.webp';

const industries = [
	{ name: 'E-Commerce', images: [ecommerceImg, ecommerceTismImg], content: 'Content for E-Commerce' },
	{ name: 'Healthcare', images: [healthcareImg, healthcareTismImg], content: 'Content for Healthcare' },
	{ name: 'Real Estate', images: [realEstateImg, realEstateTismImg], content: 'Content for Real Estate' },
	{ name: 'Education', images: [educationImg, educationTismImg], content: 'Content for Education' },
	{ name: 'Finance', images: [financeImg, financeTismImg], content: 'Content for Finance' },
	{ name: 'Hospitality', images: [hospitalityImg, hospitalityTismImg], content: 'Content for Hospitality' },
	{ name: 'Restaurants', images: [restaurantsImg, restaurantsTismImg], content: 'Content for Restaurants' },
	{ name: 'Legal', images: [legalImg, legalTismImg], content: 'Content for Legal' },
	{ name: 'Entertainment', images: [entertainmentImg, entertainmentTismImg], content: 'Content for Entertainment' },
	{ name: 'Technology', images: [technologyImg, technologyTismImg], content: 'Content for Technology' },
	{ name: 'Professional', images: [professionalImg, professionalTismImg], content: 'Content for Professional' },
];

const IndustriesComponent = () => {
	const [activeIndustry, setActiveIndustry] = useState(0);
	const carouselRef = useRef(null);

	useEffect(() => {
		scrollToCenter(activeIndustry);
	}, [activeIndustry]);

	const handleTabClick = (index) => {
		setActiveIndustry(index);
	};

	const scrollCarousel = (direction) => {
		const newIndex = activeIndustry + direction;
		if (newIndex >= 0 && newIndex < industries.length) {
			setActiveIndustry(newIndex);
		}
	};

	const scrollToCenter = (index) => {
		if (carouselRef.current) {
			const tab = carouselRef.current.children[index];
			const scrollX = tab.offsetLeft - (carouselRef.current.offsetWidth / 2) + (tab.offsetWidth / 2);
			carouselRef.current.scroll({ left: scrollX, behavior: 'smooth' });
		}
	};

	return (
		<div className="industries-component">
			<h3 className="section-title">INDUSTRIES</h3>
			<h2 className="focus-title">
				<span className="black-text">Focus</span> <span className="colored-text">Industries</span>
			</h2>
			<hr className="separator" />
			<div className="carousel-wrapper">
				<FontAwesomeIcon icon={faArrowLeft} className="carousel-control left" onClick={() => scrollCarousel(-1)} />
				<div className="tabs-container" ref={carouselRef}>
					{industries.map((industry, index) => (
						<div
							key={index}
							className={`tab ${index === activeIndustry ? 'active' : ''}`}
							onClick={() => handleTabClick(index)}
						>
							{industry.name.toUpperCase()}
						</div>
					))}
				</div>
				<FontAwesomeIcon icon={faArrowRight} className="carousel-control right" onClick={() => scrollCarousel(1)} />
			</div>
			<div className="industry-content">
				<div className="images-container">
					<img src={industries[activeIndustry].images[1]} alt={`${industries[activeIndustry].name} TISM Image`} />
					<img src={industries[activeIndustry].images[0]} alt={`${industries[activeIndustry].name} Secondary Image`} className="desktop-only" />
				</div>
				<div className="text-content">
					<h2 className="industry-title">{industries[activeIndustry].name}</h2>
					<hr className="separator" />
					<p>{industries[activeIndustry].content}</p>
					<button className="learn-more-button">Learn More</button>
				</div>
			</div>
			<div className="industry-footer">
				<span>{activeIndustry + 1}</span>
				<hr className="footer-separator" />
				<span>{industries.length}</span>
			</div>
		</div>
	);
};

export default IndustriesComponent;
