import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch, faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import './ServicesComponent.scss';

const ServicesComponent = ({ services, onLearnMore, selectedService, carouselRef, scrollCarousel }) => {

	const scrollToCenter = (index) => {
		onLearnMore(index);
		// Scroll logic to center the selected item
		const itemWidth = carouselRef.current.children[index].offsetWidth;
		const scrollTo = itemWidth * index - window.innerWidth / 2 + itemWidth / 2;
		carouselRef.current.scroll({ left: scrollTo, behavior: 'smooth' });
	};

	return (
		<div className="services-component">
			<h2 className="services-header">SERVICES</h2>
			<h1 className="services-title"><span className="highlight">Qualified</span> Team Of IT Engineers</h1>
			<p className="services-description">Many years of experience servicing multiple industries and responding to individual client needs have made us a full-service software development company</p>

			<div className="carousel-wrapper">
				<FontAwesomeIcon icon={faArrowLeft} className="carousel-control left" onClick={() => scrollCarousel(-1)} />
				<div className="carousel-container" ref={carouselRef}>
					{services.map((service, index) => (
						<div
							key={index}
							className={`carousel-item ${selectedService === index ? 'selected' : ''}`}
							onClick={() => scrollToCenter(index)}
						>
							<FontAwesomeIcon icon={faCircleNotch} size="2x" />
							<h4>{service.name}</h4>
							<p className="short-description">{service.shortDescription}</p>
							<p className="long-description">{service.longDescription}</p>
							<button className="learn-more">Learn More</button>
						</div>
					))}
				</div>
				<FontAwesomeIcon icon={faArrowRight} className="carousel-control right" onClick={() => scrollCarousel(1)} />
			</div>
		</div>
	);
};

export default ServicesComponent;
