import React, { useState, useEffect } from 'react';
import Header from './components/Header'; // Adjust the path
import HomePage from './pages/HomePage'; // Adjust the path
import Websites from './pages/Websites'; // Adjust the path
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.scss';  // Import the SCSS file for App component if exists

function App() {
	const [activeLink, setActiveLink] = useState('home');
	useEffect(() => {
		const setVh = () => {
			const vh = window.innerHeight * 0.01;
			document.documentElement.style.setProperty('--vh', `${vh}px`);
		};

		setVh();
		window.addEventListener('resize', setVh);

		return () => window.removeEventListener('resize', setVh);
	}, []);
	return (
		<Router>
			<div className="App">
				<Header activeLink={activeLink} setActiveLink={setActiveLink} />
				<Routes>
					<Route path="/" element={<HomePage activeLink={activeLink} />} />
					<Route path="/websites" element={<Websites activeLink={activeLink} />} />
				</Routes>
			</div>
		</Router>
	);
}

export default App;
