import React, { useState, useRef } from 'react';
import './ReviewComponent.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight, faStar } from '@fortawesome/free-solid-svg-icons'; // Import faStar for the review icons

const reviews = [
	{
		title: "DATA SOLUTIONS",
		content: "Your review content for Data Solutions...",
		author: "John Doe",
		tagline: "DESIGN QUALITY",
		image: "profile1.jpeg"
	},
	// ... other existing reviews
	{
		title: "NEW TITLE 1",
		content: "Your review content for New Title 1...",
		author: "Author 1",
		tagline: "NEW TAGLINE 1",
		image: "profile2.jpeg"
	},
	{
		title: "NEW TITLE 2",
		content: "Your review content for New Title 2...",
		author: "Author 2",
		tagline: "NEW TAGLINE 2",
		image: "profile3.jpeg"
	}
];

function ReviewComponent() {
	const [selectedReview, setSelectedReview] = useState(0);
	const reviewsRef = useRef(null);

	const handleSelectReview = (index) => {
		setSelectedReview(index);
		centerReviewTitle(index);
	};

	const scrollReview = (direction) => {
		let newIndex = selectedReview + direction;
		if (newIndex < 0) newIndex = reviews.length - 1;
		else if (newIndex >= reviews.length) newIndex = 0;
		setSelectedReview(newIndex);
		centerReviewTitle(newIndex);
	};

	const centerReviewTitle = (index) => {
		const titles = reviewsRef.current.children;
		const selectedTitle = titles[index];
		const scrollX = selectedTitle.offsetLeft + selectedTitle.offsetWidth / 2 - reviewsRef.current.offsetWidth / 2;
		reviewsRef.current.scroll({ left: scrollX, behavior: 'smooth' });
	};

	return (
		<div className="review-component">
			<h2 className="section-title">CUSTOMER'S REVIEWS</h2>
			<h1 className="engagement-text">
				<span className="highlight">Engage </span>
				<span>Your </span><br />
				<span>Customers.</span>
			</h1>
			<div className="reviews-navigation">
				<FontAwesomeIcon className="carousel-control left" icon={faArrowLeft} onClick={() => scrollReview(-1)} />
				<div className="reviews-titles" ref={reviewsRef}>
					{reviews.map((review, index) => (
						<div
							key={index}
							className={`review-title ${index === selectedReview ? 'active' : ''}`}
							onClick={() => handleSelectReview(index)}
						>
							<FontAwesomeIcon icon={faStar} />
							{review.title}
						</div>
					))}
				</div>
				<FontAwesomeIcon className="carousel-control right" icon={faArrowRight} onClick={() => scrollReview(1)} />
			</div>
			<div className="review-content">
				<img src={require(`../assets/${reviews[selectedReview].image}`)} alt="Customer" className="customer-image" />
				<div className="tagline">{reviews[selectedReview].tagline}</div>
				<p className="review-text">{reviews[selectedReview].content}</p>
				<div className="review-author">{reviews[selectedReview].author}</div>
			</div>
		</div>
	);
}

export default ReviewComponent;
