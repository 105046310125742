import React, { useState, useRef, useEffect } from 'react';
import Subheader from '../components/Subheader';
import CoverComponent from '../components/CoverComponent';
import ServicesComponent from '../components/ServicesComponent';
import ContactForm from '../components/ContactForm';
import HeadlineComponent from '../components/HeadlineComponent'; // Import the component
import IndustriesComponent from '../components/IndustriesComponent'; // Import the component
import TechnologiesComponent from '../components/TechnologiesComponent'; // Import the component
import ReviewComponent from '../components/ReviewComponent'; // Import the component
import './Websites.scss';

// Dummy services array
const services = [
	{ name: 'Service 1', shortDescription: 'Short description of Service 1', longDescription: 'Long description of Service 1' },
	{ name: 'Service 2', shortDescription: 'Short description of Service 2', longDescription: 'Long description of Service 2' },
	{ name: 'Service 3', shortDescription: 'Short description of Service 3', longDescription: 'Long description of Service 3' },
	{ name: 'Service 4', shortDescription: 'Short description of Service 4', longDescription: 'Long description of Service 4' },
	{ name: 'Service 5', shortDescription: 'Short description of Service 5', longDescription: 'Long description of Service 5' },
	{ name: 'Service 6', shortDescription: 'Short description of Service 6', longDescription: 'Long description of Service 6' },
	{ name: 'Service 7', shortDescription: 'Short description of Service 7', longDescription: 'Long description of Service 7' }
];

const Websites = () => {
	const backgroundColor = '#5a6ed5';
	const [showContact, setShowContact] = useState(false);
	const [selectedService, setSelectedService] = useState(null);
	const carouselRef = useRef(null);

	const toggleServiceSelection = (serviceIndex) => {
		setSelectedService(serviceIndex === selectedService ? null : serviceIndex);
	};

	const toggleContact = () => {
		setShowContact(!showContact);
	};

	useEffect(() => {
		if (selectedService !== null) {
			scrollToCenter(selectedService);
		}
	}, [selectedService]);

	const scrollToCenter = (index) => {
		if (carouselRef.current) {
			const itemWidth = carouselRef.current.children[index].offsetWidth;
			const scrollX = itemWidth * index - (window.innerWidth / 2) + (itemWidth / 2);
			carouselRef.current.scroll({ left: scrollX, behavior: 'smooth' });
		}
	};

	const scrollCarousel = (direction) => {
		let newSelectedIndex = selectedService + direction;
		if (newSelectedIndex >= services.length) {
			newSelectedIndex = 0;
		} else if (newSelectedIndex < 0) {
			newSelectedIndex = services.length - 1;
		}
		setSelectedService(newSelectedIndex);
	};
	return (
		<div className="websites">
			<Subheader backgroundColor={backgroundColor} />
			<CoverComponent toggleContact={toggleContact} />
			<ServicesComponent
				services={services}
				onLearnMore={toggleServiceSelection}
				selectedService={selectedService}
				carouselRef={carouselRef}
				scrollCarousel={scrollCarousel}
			/>
			<HeadlineComponent />
			<IndustriesComponent />
			<TechnologiesComponent />
			<ReviewComponent />
			{showContact && (
				<div className="backdrop" onClick={toggleContact}>
					<ContactForm onClose={toggleContact} />
				</div>
			)}
		</div>
	);
};

export default Websites;
