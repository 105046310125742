import React from 'react';
import './HeadlineComponent.scss';

const HeadlineComponent = () => {
	return (
		<div className="headline-component">
			<div className="left-column">
				<p>Placeholder text in small grey</p>
			</div>
			<div className="right-column">
				<h1>
					Get A Head Start With <span className="highlight">AiBud</span> and Let AI Work
					<span className="underline"> Alongside You</span>
				</h1>
				<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
			</div>
		</div>
	);
};

export default HeadlineComponent;
