import React, { useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import './TechnologiesComponent.scss'; // Make sure to create and import this SCSS file

const technologies = [
	{ name: 'JavaScript', iconClass: 'devicon-javascript-plain colored', description: 'Essential for web development.' },
	{ name: 'Python', iconClass: 'devicon-python-plain colored', description: 'Popular for its simplicity and readability.' },
	{ name: 'Java', iconClass: 'devicon-java-plain colored', description: 'Widely used for enterprise-level applications.' },
	{ name: 'C#', iconClass: 'devicon-csharp-plain colored', description: 'Commonly used with .NET framework.' },
	{ name: 'PHP', iconClass: 'devicon-php-plain colored', description: 'A staple in web development, especially for server-side scripting.' },
	{ name: 'Ruby', iconClass: 'devicon-ruby-plain colored', description: 'Known for Ruby on Rails and ease of use.' },
	{ name: 'C++', iconClass: 'devicon-cplusplus-plain colored', description: 'Used for system/software development.' },
	{ name: 'TypeScript', iconClass: 'devicon-typescript-plain colored', description: 'Superset of JavaScript, adds static types.' },
	{ name: 'Swift', iconClass: 'devicon-swift-plain colored', description: 'For iOS and macOS applications.' },
	{ name: 'Kotlin', iconClass: 'devicon-kotlin-plain colored', description: 'Increasingly popular for Android development.' },
	{ name: 'Go', iconClass: 'devicon-go-plain colored', description: 'Known for its efficiency and concurrency support.' },
	{ name: 'Rust', iconClass: 'devicon-rust-plain colored', description: 'Focused on safety and performance.' },
	{ name: 'SQL', iconClass: 'devicon-mysql-plain colored', description: 'Essential for database management.' },
	// Frameworks and Libraries
	{ name: 'React', iconClass: 'devicon-react-original colored', description: 'For building user interfaces, particularly single-page applications.' },
	{ name: 'Angular', iconClass: 'devicon-angularjs-plain colored', description: 'A comprehensive framework for building dynamic web apps.' },
	{ name: 'Vue.js', iconClass: 'devicon-vuejs-plain colored', description: 'A progressive framework for building UIs.' },
	{ name: 'Django', iconClass: 'devicon-django-plain colored', description: 'High-level framework that encourages rapid development.' },
	{ name: 'Ruby on Rails', iconClass: 'devicon-rails-plain colored', description: 'A server-side web application framework.' },
	{ name: 'ASP.NET', iconClass: 'devicon-dot-net-plain colored', description: 'For building dynamic web pages.' },
	{ name: 'Laravel', iconClass: 'devicon-laravel-plain colored', description: 'A web application framework with expressive syntax.' },
	{ name: 'Spring Boot', iconClass: 'devicon-spring-plain colored', description: 'Used to create stand-alone, production-grade Spring-based applications.' },
	{ name: 'Flutter', iconClass: 'devicon-flutter-plain colored', description: 'UI toolkit for building natively compiled applications for mobile, web, and desktop.' },
	{ name: 'Node.js', iconClass: 'devicon-nodejs-plain colored', description: 'JavaScript runtime built on Chrome\'s V8 JavaScript engine, particularly for backend.' },
	{ name: 'Express.js', iconClass: 'devicon-express-original colored', description: 'A minimal and flexible Node.js web application framework.' },
	{ name: 'Bootstrap', iconClass: 'devicon-bootstrap-plain colored', description: 'Front-end framework for developing responsive and mobile-first websites.' },
	// Other Technologies and Tools
	{ name: 'Docker', iconClass: 'devicon-docker-plain colored', description: 'A tool designed to make it easier to create, deploy, and run applications by using containers.' },
	{ name: 'Git', iconClass: 'devicon-git-plain colored', description: 'Version control system for tracking changes in source code.' },
	{ name: 'Kubernetes', iconClass: 'devicon-kubernetes-plain colored', description: 'An open-source system for automating deployment, scaling, and management of containerized applications.' },
	{ name: 'AWS', iconClass: 'devicon-amazonwebservices-original colored', description: 'A comprehensive cloud computing platform.' },
	{ name: 'Azure', iconClass: 'devicon-azure-plain colored', description: 'Cloud computing service created by Microsoft.' },
	{ name: 'Google Cloud Platform', iconClass: 'devicon-googlecloud-plain colored', description: 'Suite of cloud computing services.' },
	{ name: 'MongoDB', iconClass: 'devicon-mongodb-plain colored', description: 'NoSQL database program.' },
	{ name: 'MySQL/PostgreSQL', iconClass: 'devicon-postgresql-plain colored', description: 'Popular relational database systems.' },
	{ name: 'Redis', iconClass: 'devicon-redis-plain colored', description: 'In-memory data structure store, used as a database, cache, and message broker.' },
	{ name: 'GraphQL', iconClass: 'devicon-graphql-plain colored', description: 'A data query and manipulation language for APIs.' },
	{ name: 'Apache Kafka', iconClass: 'devicon-apachekafka-original colored', description: 'A framework for building real-time data pipelines and streaming apps.' },
	{ name: 'Selenium', iconClass: 'devicon-selenium-original colored', description: 'A suite of tools for automating web browsers.' }
];

const TechnologiesComponent = () => {
	const [activeTech, setActiveTech] = useState(0);
	const carouselRef = useRef(null);

	const handleTechClick = (index) => {
		setActiveTech(index);
		scrollToCenter(index);
	};

	const scrollCarousel = (direction) => {
		const newIndex = activeTech + direction;
		if (newIndex >= 0 && newIndex < technologies.length) {
			setActiveTech(newIndex);
			scrollToCenter(newIndex); // Ensure the new active item is centered
		}
	};

	const scrollToCenter = (index) => {
		if (carouselRef.current) {
			const item = carouselRef.current.children[index];
			const scrollX = item.offsetLeft + item.offsetWidth / 2 - carouselRef.current.offsetWidth / 2;
			carouselRef.current.scrollTo({ left: scrollX, behavior: 'smooth' });
		}
	};

	return (
		<div className="technologies-component">
			<hr className="top-separator" />
			<h3 className="section-title">TECHNOLOGIES WE USE</h3>
			<div className="carousel-wrapper">
				<FontAwesomeIcon icon={faArrowLeft} className="carousel-control left" onClick={() => scrollCarousel(-1)} />
				<div className="carousel-container" ref={carouselRef}>
					{technologies.map((tech, index) => (
						<div
							key={index}
							className={`carousel-item ${index === activeTech ? 'active' : ''}`}
							onClick={() => handleTechClick(index)}
						>
							<i className={`${tech.iconClass} icon`}></i>
						</div>
					))}
				</div>
				<FontAwesomeIcon icon={faArrowRight} className="carousel-control right" onClick={() => scrollCarousel(1)} />
			</div>
			<div className="technology-info">
				<h2 className="technology-title">{`${technologies[activeTech].name} - ${technologies[activeTech].description}`}</h2>
			</div>
		</div>
	);
};

export default TechnologiesComponent;
