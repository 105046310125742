import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe, faBars } from '@fortawesome/free-solid-svg-icons';
import './Subheader.scss'; // Import the Subheader styles

const Subheader = ({ backgroundColor }) => {
	const [isMenuOpen, setIsMenuOpen] = useState(false);

	const toggleMenu = () => {
		setIsMenuOpen(!isMenuOpen);
	};

	return (
		<div className="subheader" style={{ backgroundColor: backgroundColor }}>
			<div className="mobile-menu-icon" onClick={toggleMenu}>
				{/* Use faBars for hamburger menu */}
				<FontAwesomeIcon icon={faBars} />
			</div>
			<div className="website-link">
				<FontAwesomeIcon icon={faGlobe} />
				<span>Websites</span>
			</div>
			<div className={`nav-links ${isMenuOpen ? 'open' : ''}`}>
				{/* Navigation links */}
				<a href="/services">Services</a>
				<a href="/industries">Industries</a>
				<a href="/solutions">Solutions</a>
				<a href="/faqs">FAQs</a>
				<a href="/case-studies">Case Studies</a>
				<a href="/reviews">Reviews</a>
			</div>
		</div>
	);
};

export default Subheader;
