// Header.js
import React, { useState, useEffect } from 'react';
import './Header.scss';
import LogoHome from '../assets/logo_home.png';
import LogoWebsites from '../assets/logo_websites.png';
import LogoSoftware from '../assets/logo_software.png';
import LogoTech from '../assets/logo_tech.png';
import LogoMarketing from '../assets/logo_marketing.png';
import { NavLink } from 'react-router-dom';
import { FaBars } from 'react-icons/fa';

function Header({ activeLink, setActiveLink }) {
	const [activeColor, setActiveColor] = useState('');
	const [isMenuOpen, setIsMenuOpen] = useState(false);

	const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

	useEffect(() => {
		const rootStyle = getComputedStyle(document.documentElement);
		switch (activeLink) {
			case 'home':
				setActiveColor(rootStyle.getPropertyValue('--home-color').trim());
				break;
			case 'websites':
				setActiveColor(rootStyle.getPropertyValue('--websites-color').trim());
				break;
			case 'software':
				setActiveColor(rootStyle.getPropertyValue('--software-color').trim());
				break;
			case 'techsupport':
				setActiveColor(rootStyle.getPropertyValue('--techsupport-color').trim());
				break;
			case 'marketing':
				setActiveColor(rootStyle.getPropertyValue('--marketing-color').trim());
				break;
			default:
				setActiveColor('#000'); // Default color
		}
	}, [activeLink]);

	const handleLinkClick = (link) => {
		console.log("Link clicked:", link); // Add this line for debugging
		setActiveLink(link);
	};

	const logoSrc = {
		'home': LogoHome,
		'websites': LogoWebsites,
		'software': LogoSoftware,
		'techsupport': LogoTech,
		'marketing': LogoMarketing,
	}[activeLink] || LogoHome; // default

	return (
		<div className="header">
			<div className="mobile-menu-icon" onClick={toggleMenu}>
				<FaBars />
			</div>
			<div className="logo">
				<img src={logoSrc} alt="Logo" className="logo-image" />
				<div className="logo-text">
					<span className="tism" style={{ color: activeColor }}>T.I.S.M</span>
					<div className="description">
						Tech In Software <br /> & Marketing
					</div>
				</div>
			</div>
			<div className={`nav-links ${isMenuOpen ? 'open' : ''}`}>
				<NavLink
					to="/"
					className={`home button ${activeLink === 'home' ? 'active-link' : ''}`}
					onClick={() => { handleLinkClick('home'); toggleMenu(); }}
				>
					Home
				</NavLink>
				<NavLink
					to="/websites"
					className={`websites button ${activeLink === 'websites' ? 'active-link' : ''}`}
					onClick={() => { handleLinkClick('websites'); toggleMenu(); }}
				>
					Websites
				</NavLink>
				<NavLink
					to="/software"
					className={`software button ${activeLink === 'software' ? 'active-link' : ''}`}
					onClick={() => { handleLinkClick('software'); toggleMenu(); }}
				>
					Software
				</NavLink>
				<NavLink
					to="/techsupport"
					className={`techsupport button ${activeLink === 'techsupport' ? 'active-link' : ''}`}
					onClick={() => { handleLinkClick('techsupport'); toggleMenu(); }}
				>
					Tech Support
				</NavLink>
				<NavLink
					to="/marketing"
					className={`marketing button ${activeLink === 'marketing' ? 'active-link' : ''}`}
					onClick={() => { handleLinkClick('marketing'); toggleMenu(); }}
				>
					Marketing
				</NavLink>
			</div>
			<NavLink
				to="/get-quote"
				className={`getquote ${activeLink === 'getquote' ? 'active-link' : ''}`}
				onClick={() => handleLinkClick('getquote')}
			>
				Get Quote
			</NavLink>
		</div>
	);
}

export default Header;
