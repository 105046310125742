import React from 'react';
import { TextField, Button } from '@mui/material';
import './ContactForm.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const ContactForm = ({ onClose }) => {
	const handleModalClick = (e) => {
		e.stopPropagation();
	};
	return (
		<div className="contact-component" onClick={handleModalClick}>
			<div className="contact-header">CONTACT US</div>
			<form className="contact-form">
				<FontAwesomeIcon icon={faTimes} className="close-icon" onClick={onClose} />
				<div className="contact-form-header">Contact Us</div>
				<TextField
					label="Email Address"
					type="email"
					id="email"
					name="email"
					variant="outlined"
					fullWidth
					required
					margin="normal"
				/>

				<TextField
					label="Phone Number (Optional)"
					type="tel"
					id="phone"
					name="phone"
					variant="outlined"
					fullWidth
					margin="normal"
				/>

				<TextField
					label="How can we help you?"
					id="message"
					name="message"
					multiline
					rows={4}
					variant="outlined"
					fullWidth
					required
					margin="normal"
				/>

				<Button type="submit" variant="contained" color="primary" style={{ margin: '8px 0' }}>
					Send
				</Button>
				<Button onClick={onClose} variant="contained" color="secondary">
					Close
				</Button>
			</form>
		</div>
	);
};

export default ContactForm;
