import React from 'react';
import Footer from '../components/Footer';
import LogoHome from '../assets/logo.png';

import './HomePage.scss';

function HomePage() {
	return (
		<>
			<div className="main-container">
				<div className="body">
					<div className="left-section">
						<div className="tism-div">
							<div className="circle"></div>
							T.I.S.M
						</div>
						<h2><span>Tech In</span> <span className="software">Software</span> & <span className="marketing">Marketing</span></h2>
						<p>Ignite your IT strategy with technology solutions and support that optimize your business performance, engagement, and compliance.</p>
					</div>
					<div className="right-section">
						<div className="logo">
							<img src={LogoHome} alt="TISM Logo" style={{ maxWidth: '100%', height: 'auto' }} />
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</>
	);
}

export default HomePage;
